import { CompanyComparisonType } from 'types/companies-compare.types';
import { CustomColumnsType } from '@pages/companies-compare/types';

export const mainColumns: CustomColumnsType<CompanyComparisonType> = [
    {
        title: 'Название компании',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        unhidded: true,
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker',
        key: 'ticker',
    },
    {
        title: 'Сектор',
        dataIndex: 'sector',
        key: 'sector',
    },
    {
        title: 'Капитализация',
        dataIndex: 'market_cap',
        key: 'market_cap',
        additionalHeaderText: 'млрд ₽',
        formatBillion: true,
    },
    {
        title: 'EV TTM',
        dataIndex: 'ev_ttm',
        key: 'ev_ttm',
        additionalHeaderText: 'млрд ₽',
        redGreen: true,
        percentAtEnd: true,
        formatBillion: true,
    },
    {
        title: 'Чистый долг TTM',
        dataIndex: 'net_debt_ttm',
        key: 'net_debt_ttm',
        additionalHeaderText: 'млрд ₽',
        redGreen: true,
        percentAtEnd: true,
        formatBillion: true,
    },
    {
        title: 'Динамика котировок за 1 месяц',
        dataIndex: '1_month_quote_dynamics',
        key: '1_month_quote_dynamics',
        redGreen: true,
        percentAtEnd: true,
    },
    {
        title: 'Динамика котировок за 3 месяца',
        dataIndex: '3_months_quote_dynamics',
        key: '3_months_quote_dynamics',
        redGreen: true,
        percentAtEnd: true,
    },
    {
        title: 'Динамика котировок за 1 год',
        dataIndex: '1_year_quote_dynamics',
        key: '1_year_quote_dynamics',
        redGreen: true,
        percentAtEnd: true,
    },
    {
        title: 'Див. доходность текущая',
        dataIndex: 'dividend_yield_current',
        key: 'dividend_yield_current',
        redGreen: true,
        percentAtEnd: true,
    },
    {
        title: 'Див. доходность 2024',
        dataIndex: 'dividend_yield_2024',
        key: 'dividend_yield_2024',
        redGreen: true,
        percentAtEnd: true,
    },
];
