import { CompanyComparisonType } from 'types/companies-compare.types';
import { CustomColumnsType } from '@pages/companies-compare/types';

export const financialColumns: CustomColumnsType<CompanyComparisonType> = [
    {
        title: 'Название компании',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        unhidded: true,
    },
    {
        title: 'Тикер',
        dataIndex: 'ticker',
        key: 'ticker',
        unhidded: true,
    },
    {
        title: 'P/E TTM',
        dataIndex: 'p_s_ttm',
        key: 'p_s_ttm',
        toFixed: 2,
    },
    {
        title: 'FWD P/E 2024',
        dataIndex: 'fwd_pe_2024',
        key: 'fwd_pe_2024',
        toFixed: 2,
    },
    {
        title: 'FWD P/E 2025',
        dataIndex: 'fwd_pe_2025',
        key: 'fwd_pe_2025',
        toFixed: 2,
    },
    {
        title: 'EV/EBITDA TTM',
        dataIndex: 'ev_ebitda_ttm',
        key: 'ev_ebitda_ttm',
        toFixed: 2,
    },
    {
        title: 'FWD EV/EBITDA 2024',
        dataIndex: 'fwd_ev_ebitda_2024',
        key: 'fwd_ev_ebitda_2024',
        toFixed: 2,
    },
    {
        title: 'FWD EV/EBITDA 2025',
        dataIndex: 'fwd_ev_ebitda_2025',
        key: 'fwd_ev_ebitda_2025',
        toFixed: 2,
    },
    {
        title: 'NetDebt/EBITDA TTM',
        dataIndex: 'net_debt_ebitda_ttm',
        key: 'net_debt_ebitda_ttm',
        toFixed: 2,
    },
    {
        title: 'NetDebt/EBITDA 2024',
        dataIndex: 'net_debt_ebitda_2024',
        key: 'net_debt_ebitda_2024',
        toFixed: 2,
    },
    {
        title: 'NetDebt/EBITDA 2025',
        dataIndex: 'net_debt_ebitda_2025',
        key: 'net_debt_ebitda_2025',
        toFixed: 2,
    },
    {
        title: 'ROE TTM',
        dataIndex: 'roe_ttm',
        key: 'roe_ttm',
        toFixed: 1,
        redGreen: true,
    },
    {
        title: 'ROA TTM',
        dataIndex: 'roa_ttm',
        key: 'roa_ttm',
        toFixed: 1,
        redGreen: true,
    },
    {
        title: 'Валовая маржа TTM',
        dataIndex: 'gross_margin_ttm',
        key: 'gross_margin_ttm',
        toFixed: 1,
        redGreen: true,
    },
    {
        title: 'Операционная маржа TTM',
        dataIndex: 'operating_margin_ttm',
        key: 'operating_margin_ttm',
        toFixed: 1,
        redGreen: true,
    },
    {
        title: 'Маржа EBITDA TTM',
        dataIndex: 'ebitda_margin_ttm',
        key: 'ebitda_margin_ttm',
        toFixed: 1,
        redGreen: true,
    },
    {
        title: 'Маржа чистой прибыли TTM',
        dataIndex: 'net_profit_margin_ttm',
        key: 'net_profit_margin_ttm',
        render: (value: number | null) => (value !== null ? `${value}%` : '—'),
    },
];
